import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react';
import { ColumnSize } from "@react-types/table";
import { Segment } from '../models/Segment';
import { Status } from '../models/Segmentation';

interface SegmentsComponentProps {
  onSelectedSegmentChange: (segment: Segment) => void;
  segments?: Segment[];
  selectedSegmentId?: string;
  status: Status;
  isLoading: boolean;
  error: string | null;
}

type SegmentsColumnKey = 'name' | 'size' | 'expected_outcome';

const segmentsTableColumns: { key: SegmentsColumnKey, label: string, width: ColumnSize | null }[] = [
  { key: 'name', label: 'Name', width: null },
  { key: 'size', label: 'Size', width: 100 },
  { key: 'expected_outcome', label: 'Expected Outcome', width: null }
];

const SegmentsComponent = observer((props: SegmentsComponentProps) => {
  const { segments, selectedSegmentId, status, isLoading, error, onSelectedSegmentChange } = props;

  const renderSegmentCell = useCallback((segment: Segment, columnKey: SegmentsColumnKey) => {
    switch (columnKey) {
      case 'name':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{segment.name}</span>
          </div>
        )
      case 'size':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{segment.size}</span>
          </div>
        )
      case 'expected_outcome':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{segment.expected_outcome?.toFixed(2)}</span>
          </div>
        )
      default:
        return "nothing"
    }
  }, []);

  if (isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  if (status !== Status.PROCESS_DONE && status !== Status.ERROR && status !== Status.STOPPED) {
    return (
      <div className='flex flex-col h-full'>
        <Spinner />
      </div>
    );
  }

  return (
    <Table
      aria-label="Segmentation control table with dynamic content"
      className='flex flex-col h-full'
      layout='fixed'
      isHeaderSticky
      onSelectionChange={(keys) => {
        const selectedKey = Array.from(keys)[0];
        const selectedSegment = segments?.find(seg => seg.id?.toString() === selectedKey?.toString());
        if (selectedSegment) {
          onSelectedSegmentChange(selectedSegment);
        }
      }}
      selectedKeys={[selectedSegmentId || '']}
      selectionMode="single"
      selectionBehavior="replace"
    >
      <TableHeader columns={segmentsTableColumns}>
        {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={segments}>
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => {
              return <TableCell>{renderSegmentCell(item, columnKey as SegmentsColumnKey)}</TableCell>
            }}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
});

export default SegmentsComponent;