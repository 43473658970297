import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Select, SelectItem } from "@nextui-org/select";
import {
  Button,
  Input,
  Pagination,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
} from "@nextui-org/react";

import RecipientsPageViewModel, { Recipient } from '../viewModels/RecipientsPageViewModel';

const columns = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "phone",
    label: "Phone",
  },
  {
    key: "is_synthetic",
    label: "Is Synthetic",
  }
];

const RecipientsPage = observer(() => {
  const [viewModel] = useState(() => new RecipientsPageViewModel());
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const searchRecipients = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    viewModel.fetchData(search, currentPage - 1);
  }, [search, currentPage, viewModel]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page)
    viewModel.fetchData(search, page - 1)
  }, [search, viewModel]);

  const handlePerPageChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    viewModel.setItemsPerPage(Number(e.target.value));
    setCurrentPage(1)
    viewModel.fetchData(search, 0)
  }, [search, viewModel]);

  useEffect(() => {
    viewModel.fetchData('', 0)
  }, [viewModel])

  const renderCell = useCallback((recipient: Recipient, columnKey: React.Key) => {
    const cellValue = recipient[columnKey as keyof Recipient];

    switch (columnKey) {
      case "name":
        return (
          <p>{`${recipient.first_name}, ${recipient.last_name}`}</p>
        );
      case "email":
        return (
          <p>{cellValue}</p>
        );
      case "phone":
        return (
          <p>{cellValue}</p>
        );
      case "is_synthetic":
        return (
          <p>{cellValue ? 'Yes' : 'No'}</p>
        );
      default:
        return cellValue;
    }
  }, []);

  const { recipients, totalPages } = viewModel.data;
  const { itemsPerPage } = viewModel;

  const topContent = React.useMemo(() => {
    return (
      <form onSubmit={searchRecipients} className='flex gap-2'>
        <Input
          isClearable
          placeholder="Search recipients"
          value={search}
          onClear={() => setSearch('')}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button color='primary' type='submit'>Search</Button>
      </form>
    )
  }, [searchRecipients, search])

  if (viewModel.isLoading) {
    <div className='w-full flex items-center justify-center'>
      <Spinner />
    </div>
  }

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  const bottomContent = React.useMemo(() => {
    return (
      <div className='flex justify-center items-center gap-2'>
        <Pagination
          className='self-center'
          initialPage={1}
          total={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
        <Select
          className='w-36'
          label="Results per page"
          selectedKeys={[itemsPerPage?.toString()]}
          onChange={handlePerPageChange}
          size='sm'
        >
          <SelectItem key={10} value="10">10</SelectItem>
          <SelectItem key={20} value="20">20</SelectItem>
          <SelectItem key={50} value="50">50</SelectItem>
        </Select>
      </div>
    )
  }, [totalPages, currentPage, itemsPerPage, handlePageChange, handlePerPageChange])

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <Table
          aria-label="Recipients table"
          bottomContent={bottomContent}
          bottomContentPlacement='outside'
          isStriped
          isHeaderSticky
          className="h-full"
          topContent={topContent}
          topContentPlacement='outside'
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody items={recipients}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div >
  )
});

export default RecipientsPage;