import BaseViewModel from './BaseViewModel';
import AuthStore from '../stores/AuthStore';

export default class AuthenticationViewModel extends BaseViewModel {
  isLoading = false;
  error: string | null = null;

  constructor() {
    super();  
  }

  async exchangeCodeForToken(code: string): Promise<string | null> {
    try {
      const response = await this.api.post('/auth/token', { code });
      const token = response.data.token;
      AuthStore.setToken(token);
      return token;
    } catch (error) {
      return null;
    }
  }
}