import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Select, SelectItem } from "@nextui-org/select";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/modal";
import {
  Button,
  Checkbox,
  Input,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Textarea,
  Tooltip,
  Spinner,
} from "@nextui-org/react";

import { EditIcon } from '../assets/icons/EditIcon';
import { DeleteIcon } from '../assets/icons/DeleteIcon';
import { getTypedFormData } from '../utils/TypeFormData';
import AttributesPageViewModel, { Attribute } from '../viewModels/AttributesPageViewModel';

import { NormalizationEnum } from '../constants/normalizations';
import { ColumnSize } from "@react-types/table";
import { Toaster } from 'react-hot-toast';

const columns: { key: string; label: string; width: ColumnSize | null }[] = [
  {
    key: "name",
    label: "Name",
    width: null
  },
  // {
  //   key: "description",
  //   label: "Description",
  //   width: null
  // },
  {
    key: "normalization",
    label: "Normalization",
    width: 100
  },
  {
    key: "is_outcome",
    label: "Is Outcome",
    width: 100
  },
  {
    key: "actions",
    label: "Actions",
    width: 80
  },
];

type FormData = {
  id: string;
  name: string;
  description: string;
  normalization: string;
  is_outcome: string;
}

const AttributesPage: React.FC = observer(() => {
  const [viewModel] = useState(() => new AttributesPageViewModel());
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    viewModel.fetchAttributes();
  }, [viewModel]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = getTypedFormData<FormData>(event.currentTarget);
    viewModel.saveAttribute({
      id: formData.get('id') || undefined,
      name: formData.get('name'),
      description: formData.get('description'),
      normalization: formData.get('normalization') as NormalizationEnum,
      is_outcome: formData.get('is_outcome') === 'true'
    });
    setOpenDialog(false);
  }

  const handleDeleteAttribute = (id: string) => {
    viewModel.deleteAttribute(id);
  }

  const { currentAttribute } = viewModel;

  const renderCell = (attribute: Attribute, columnKey: React.Key) => {
    const cellValue = attribute[columnKey as keyof Attribute];

    switch (columnKey) {
      case "name":
        return (
          <div className="flex flex-col">
            <span className="text-sm">{cellValue}</span>
            <span className="text-tiny text-default-500">{attribute.description}</span>
          </div>
        );
      case "is_outcome":
        return (
          <span className="text-sm">{cellValue ? 'Yes' : 'No'}</span>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <span onClick={() => {
                viewModel.setCurrentAttribute(attribute);
                setOpenDialog(true);
              }} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete">
              <span onClick={() => {
                if (attribute.id) {
                  handleDeleteAttribute(attribute.id)
                }
              }} className="text-lg text-danger cursor-pointer active:opacity-50">
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return cellValue;
    }
  }

  const topContent = React.useMemo(() => {
    return (
      <Button
        className='w-fit'
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Add Attribute
      </Button>
    )
  }, [setOpenDialog])

  if (viewModel.isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <Toaster />
        <Modal
          isOpen={openDialog}
          onOpenChange={() => setOpenDialog(false)}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader className="flex flex-col gap-1">{currentAttribute.id ? 'Edit Attribute' : 'Add Attribute'}</ModalHeader>
                <ModalBody>
                  <Input
                    className='hidden'
                    name="id"
                    hidden
                    type="text"
                    defaultValue={viewModel.currentAttribute.id?.toString()}
                  />
                  <Input
                    name="name"
                    label="Attribute Name"
                    type="text"
                    required
                    isRequired
                    defaultValue={viewModel.currentAttribute.name}
                  />
                  <Textarea
                    name="description"
                    label="Description"
                    type="text"
                    defaultValue={viewModel.currentAttribute.description}
                  />
                  <Select
                    name="normalization"
                    label="Normalization"
                    defaultSelectedKeys={[viewModel.currentAttribute.normalization]}
                  >
                    {Object.values(NormalizationEnum).map((option) => (
                      <SelectItem key={option} value={option}>
                        {option}
                      </SelectItem>
                    ))}
                  </Select>
                  <Checkbox
                    name="is_outcome"
                    defaultSelected={!!viewModel.currentAttribute.is_outcome}
                    value="true"
                  >Is outcome</Checkbox>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onPress={onClose}>
                    Close
                  </Button>
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </ModalFooter>
              </form>
            )}
          </ModalContent>
        </Modal >
        <Table
          aria-label="Example table with dynamic content"
          className="h-full"
          isHeaderSticky
          layout="fixed"
          topContent={topContent}
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody items={viewModel.attributes}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell
                    className={columnKey === "name" ? "truncate" : ""}
                  >
                    {renderCell(item, columnKey)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div >
  );
});

export default AttributesPage;