import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Spinner,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Textarea
} from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input } from "@nextui-org/react";
import { ColumnSize } from "@react-types/table";
import toast, { Toaster } from "react-hot-toast";

import { EditIcon } from '../assets/icons/EditIcon';
import { DeleteIcon } from '../assets/icons/DeleteIcon';
import CriteriaPageViewModel from '../viewModels/CriteriaPageViewModel';
import Criterion from '../models/Criterion';

const columns: { key: string; label: string; width: ColumnSize | null }[] = [
  {
    key: "name",
    label: "Name",
    width: 150
  },
  {
    key: "prompt",
    label: "Prompt",
    width: null
  },
  {
    key: "actions",
    label: "Actions",
    width: 100
  },
];

const CriteriaPage = observer(() => {
  const [viewModel] = useState(() => new CriteriaPageViewModel());

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [criterionToDelete, setCriterionToDelete] = useState<Criterion | null>(null);

  const renderCell = useCallback((criterion: Criterion, columnKey: React.Key) => {
    const cellValue = criterion[columnKey as keyof Criterion];

    switch (columnKey) {
      case "name":
        return (
          <span className="text-sm">{cellValue}</span>
        );
      case "prompt":
        return (
          <div className="m-w-full">
            <p className="text-sm line-clamp-2">{cellValue}</p>
          </div>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <span onClick={() => viewModel.openDialog(criterion)} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete">
              <span onClick={() => {
                setCriterionToDelete(criterion);
                setIsDeleteModalOpen(true);
              }} className="text-lg text-danger cursor-pointer active:opacity-50">
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return cellValue;
    }
  }, [viewModel]);

  const saveCriterion = (onClose: () => void) => {
    viewModel.saveCriterion().then(() => {
      toast.success('Criterion added');
      onClose();
    });
  }

  useEffect(() => {
    viewModel.fetchCriteria();
  }, [viewModel]);

  const topContent = React.useMemo(() => {
    return (
      <Button
        className='w-fit'
        color="primary"
        onClick={() => viewModel.openDialog()}
      >
        Add Criterion
      </Button>
    )
  }, [viewModel])

  if (viewModel.isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className="text-danger">{viewModel.error}</p>
      </div>
    );
  }

  const { currentCriterion } = viewModel;

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <Toaster />
        <Modal
          isOpen={viewModel.isDialogOpen}
          onOpenChange={() => viewModel.closeDialog()}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">{viewModel.isEditing ? 'Edit Criterion' : 'Add Criterion'}</ModalHeader>
                <ModalBody>
                  <Input
                    label="Criterion Name"
                    type="text"
                    value={currentCriterion.name}
                    onValueChange={(value) => viewModel.setCurrentCriterion('name', value)}
                  />
                  <Textarea
                    label="Prompt"
                    type="text"
                    value={currentCriterion.prompt}
                    onValueChange={(value) => viewModel.setCurrentCriterion('prompt', value)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onPress={onClose}>
                    Close
                  </Button>
                  <Button color="primary" onPress={() => saveCriterion(onClose)}>
                    Save
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onOpenChange={(open) => {
            setIsDeleteModalOpen(open);
            if (!open) setCriterionToDelete(null);
          }}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Confirm Deletion</ModalHeader>
                <ModalBody>
                  <p>{`Are you sure you want to delete criterion ${criterionToDelete?.name}?`}</p>
                  <p className="text-sm text-default-400">This action cannot be undone.</p>
                </ModalBody>
                <ModalFooter>
                  <Button color="default" variant="flat" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button
                    color="danger"
                    onPress={() => {
                      if (criterionToDelete) {
                        viewModel.deleteCriterion(criterionToDelete.id)
                          .then(() => {
                            toast.success('Criterion deleted');
                            onClose();
                          });
                      }
                    }}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Table
          aria-label="Example table with dynamic content"
          isHeaderSticky
          layout='fixed'
          className='h-full'
          topContent={topContent}
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody items={viewModel.criteria}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
});

export default CriteriaPage;