import {
  Button,
  Card,
  CardBody,
} from "@nextui-org/react";

const RAILS_AUTH_URL = process.env.REACT_APP_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

function LoginPage() {
  const handleLogin = () => {
    const authUrl = `${RAILS_AUTH_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;
    window.location.href = authUrl;
  };

  return (
    <div className="flex flex-grow justify-center items-center h-screen">
      <Card className="w-1/2">
        <CardBody className="flex flex-col gap-4 p-4">
          <h1 className="text-center text-2xl font-bold">Sign in to use Segmentation Tool</h1>
          <Button onClick={handleLogin}>
            Sign In
          </Button>
        </CardBody>
      </Card>

    </div>
  );
}

export default LoginPage;
