import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
} from "@nextui-org/react";
import { routes } from '../routes';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AuthStore from '../stores/AuthStore';
import ThemeSwitcher from './ThemeSwitcher';

const NavigationBar: React.FC = observer(() => {
  const location = useLocation();

  const logout = () => {
    AuthStore.clearToken();
  };

  const navItems = routes.filter(route => route.includeInNav).map(route => ({
    key: route.path,
    label: route.label,
    href: route.path,
  }));

  return (
    <Navbar isBordered>
      <NavbarBrand>
        <p className="font-bold text-inherit">Segmentation Tool</p>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        {navItems.map((item) => (
          <NavbarItem key={item.key} isActive={location.pathname === item.href}>
            <Link
              href={item.href}
              color={location.pathname === item.href ? 'primary' : 'foreground'}
              aria-current={location.pathname === item.href ? 'page' : undefined}
            >
              {item.label}
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem className="hidden lg:flex">
          {AuthStore.isAuthenticated && (
            <Button color="default" onClick={logout}>Logout</Button>
          )}
        </NavbarItem>
        <NavbarItem>
          <ThemeSwitcher />
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
});

export default NavigationBar;
