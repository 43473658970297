import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Select, SelectItem } from "@nextui-org/select";
import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Spinner
} from "@nextui-org/react";
import { ColumnSize } from "@react-types/table";
import toast, { Toaster } from "react-hot-toast";

import { getTypedFormData } from '../utils/TypeFormData';
import SegmentationsViewModel from '../viewModels/SegmentationsViewModel';
import Segmentation from '../models/Segmentation';
import { DeleteIcon } from '../assets/icons/DeleteIcon';
import { EditIcon } from '../assets/icons/EditIcon';

type ColumnKey = 'name' | 'created_at' | 'actions' | 'status';
const columns: { key: ColumnKey, label: string, width: ColumnSize | null }[] = [
  {
    key: "name",
    label: "Name",
    width: null
  },
  {
    key: "created_at",
    label: "Created At",
    width: 180
  },
  {
    key: "status",
    label: "Status",
    width: null
  },
  {
    key: "actions",
    label: "Actions",
    width: 100
  }
];

type FormData = {
  id?: string;
  name?: string;
}

const StatusCell = observer(({ segmentation }: { segmentation: Segmentation }) => {
  return <p>{segmentation.status}</p>;
});

const SegmentationPage = observer(() => {
  const navigate = useNavigate();
  const [viewModel] = useState(() => new SegmentationsViewModel());
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const searchSegmentations = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    viewModel.fetchData(search, currentPage - 1);
  }, [search, currentPage, viewModel]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    viewModel.fetchData(search, page - 1);
  }, [search, viewModel]);

  const handlePerPageChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    viewModel.setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
    viewModel.fetchData(search, 0);
  }, [search, viewModel]);

  useEffect(() => {
    viewModel.fetchData('', 0);
  }, [viewModel]);

  useEffect(() => {
    if (!isDialogOpen) {
      viewModel.setCurrentSegmentation(null);
    }
  }, [isDialogOpen, viewModel]);

  const renderCell = useCallback((segmentation: Segmentation, columnKey: ColumnKey) => {
    switch (columnKey) {
      case "name":
        return (
          <p>{segmentation.name}</p>
        );
      case "status":
        return <StatusCell segmentation={segmentation} />;
      case "created_at":
        return (
          <p>{segmentation.created_at ? new Date(segmentation.created_at).toLocaleString() : "N/A"}</p>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <span onClick={() => {
                viewModel.setCurrentSegmentation(segmentation)
                setIsDialogOpen(true);
              }}
                className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete">
              <span onClick={() => {
                viewModel.setCurrentSegmentation(segmentation);
                setIsDeleteDialogOpen(true);
              }}
                className="text-lg text-danger cursor-pointer active:opacity-50">
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return "none";
    }
  }, [viewModel]);

  const { segmentations, totalPages } = viewModel.data;
  const { itemsPerPage } = viewModel;

  const topContent = React.useMemo(() => {
    return (
      <div className='flex'>
        <Button
          className='w-fit'
          color="primary"
          onClick={() => setIsDialogOpen(true)}
        >
          Add Segmentation
        </Button>
      </div>
    )
  }, []);

  const bottomContent = React.useMemo(() => {
    return (
      <div className='flex justify-center items-center gap-2'>
        <Pagination
          className='self-center'
          initialPage={1}
          total={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
        <Select
          className='w-36'
          label="Results per page"
          selectedKeys={[itemsPerPage?.toString()]}
          onChange={handlePerPageChange}
          size='sm'
        >
          <SelectItem key={10} value="10">10</SelectItem>
          <SelectItem key={20} value="20">20</SelectItem>
          <SelectItem key={50} value="50">50</SelectItem>
        </Select>
      </div>
    )
  }, [totalPages, currentPage, itemsPerPage, handlePageChange, handlePerPageChange])

  const saveSegmentation = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = getTypedFormData<FormData>(e.currentTarget);
    const name = formData.get('name') as string;
    if (name) {
      if (viewModel.currentSegmentation) {
        viewModel.editSegmentation({ ...viewModel.currentSegmentation, name })
          .then((segmentation) => {
            if (segmentation) {
              navigate(`/segmentations/${segmentation.id}`);
            }
          });
      } else {
        viewModel.addSegmentation(name)
          .then((segmentation) => {
            if (segmentation) {
              navigate(`/segmentations/${segmentation.id}`);
            }
          });
      }
    }
    setIsDialogOpen(false);
  }, [viewModel, navigate]);

  if (viewModel.isLoading) {
    <div className='w-full flex items-center justify-center'>
      <Spinner />
    </div>
  }

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full gap-4'>
        <Toaster />
        <Modal
          isOpen={isDialogOpen}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setIsDialogOpen(false);
            }
          }}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">{viewModel.currentSegmentation ? 'Edit Segmentation' : 'Add Segmentation'}</ModalHeader>
                <ModalBody>
                  <form onSubmit={saveSegmentation} className="flex flex-col gap-2">
                    <Input
                      label="Name"
                      name="name"
                      id="name"
                      type="text"
                      defaultValue={viewModel.currentSegmentation?.name}
                    />
                    <div className="flex justify-end gap-2">
                      <Button color="danger" variant="flat" onPress={onClose}>
                        Close
                      </Button>
                      <Button color="primary" type='submit'>
                        Save
                      </Button>
                    </div>
                  </form>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isDeleteDialogOpen}
          onOpenChange={() => {
            viewModel.setCurrentSegmentation(null);
            setIsDeleteDialogOpen(false)
          }}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Delete Segmentation</ModalHeader>
                <ModalBody>
                  <p>{`Are you sure you want to delete segmentation ${viewModel.currentSegmentation?.name}?`}</p>
                  <p className="text-sm text-default-400">This action cannot be undone.</p>
                </ModalBody>
                <ModalFooter>
                  <Button color="default" variant="flat" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button
                    color="danger"
                    onPress={() => {
                      if (viewModel.currentSegmentation?.id) {
                        viewModel.deleteSegmentation(viewModel.currentSegmentation.id)
                          .then(() => {
                            toast.success('Segmentation deleted');
                            onClose();
                          });
                      }
                    }}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <form onSubmit={searchSegmentations} className='flex gap-2'>
          <Input
            isClearable
            placeholder="Search segmentations"
            value={search}
            onClear={() => setSearch('')}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button color='primary' type='submit'>Search</Button>
        </form>
        <Table
          aria-label="Segmentations table"
          bottomContent={bottomContent}
          bottomContentPlacement='outside'
          isHeaderSticky
          className="h-full"
          selectionMode='single'
          selectionBehavior='replace'
          topContent={topContent}
          topContentPlacement='outside'
          onSelectionChange={(keys) => {
            const selectedKey = Array.from(keys)[0];
            navigate(`/segmentations/${selectedKey}`);
          }}
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody items={segmentations}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey as ColumnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div >
  )
});

export default SegmentationPage;