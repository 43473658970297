import React from 'react';
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { Sun, Moon, Monitor } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';

type Theme = 'light' | 'dark' | 'system';

interface ThemeOption {
  key: Theme;
  label: string;
  icon: React.ReactNode;
}

const ThemeSwitcher: React.FC = () => {
  const { theme, setTheme } = useTheme();

  const themeOptions: ThemeOption[] = [
    { key: 'light', label: 'Light', icon: <Sun className="h-4 w-4" /> },
    { key: 'dark', label: 'Dark', icon: <Moon className="h-4 w-4" /> },
    { key: 'system', label: 'System', icon: <Monitor className="h-4 w-4" /> }
  ];

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button 
          isIconOnly
          variant="bordered"
          className="w-10 h-10"
        >
          {theme === 'system' && <Monitor className="h-4 w-4" />}
          {theme === 'light' && <Sun className="h-4 w-4" />}
          {theme === 'dark' && <Moon className="h-4 w-4" />}
        </Button>
      </DropdownTrigger>
      <DropdownMenu 
        aria-label="Theme selection" 
        onAction={(key) => {
          setTheme(key as Theme);
        }}
        selectedKeys={[theme]}
      >
        {themeOptions.map((option) => (
          <DropdownItem
            key={option.key}
            startContent={option.icon}
            className="flex items-center gap-2"
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ThemeSwitcher;
