import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Input, Spinner, Textarea } from '@nextui-org/react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import { ColumnSize } from "@react-types/table";
import toast, { Toaster } from "react-hot-toast";

import { EditIcon } from '../assets/icons/EditIcon';
import { DeleteIcon } from '../assets/icons/DeleteIcon';
import VariablesPageViewModel, { Variable } from '../viewModels/VariablesPageViewModel';

const columns: { key: string; label: string, width?: ColumnSize | null }[] = [
  {
    key: "name",
    label: "Name",
    width: 150
  },
  {
    key: "description",
    label: "Description",
    width: 150
  },
  {
    key: "prompt",
    label: "Prompt",
    width: null
  },
  {
    key: "actions",
    label: "Actions",
    width: 100
  },
];

const VariablesPage: React.FC = observer(() => {
  const [viewModel] = useState(() => new VariablesPageViewModel());
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [variableToDelete, setVariableToDelete] = useState<Variable | null>(null);

  useEffect(() => {
    viewModel.fetchVariables();
  }, [viewModel]);

  const handleOpenDialog = useCallback((variable: Variable | null = null) => {
    viewModel.setCurrentVariable(variable);
    viewModel.setOpenDialog(true);
  }, [viewModel]);

  const handleDeleteVariable = (variable: Variable) => {
    setVariableToDelete(variable);
    setIsDeleteModalOpen(true);
  }

  const renderCell = (variable: Variable, columnKey: React.Key) => {
    const cellValue = variable[columnKey as keyof Variable];

    switch (columnKey) {
      case "name":
        return (
          <span className="text-sm">{cellValue}</span>
        );
      case "description":
        return (
          <span className="text-sm line-clamp-2">{cellValue}</span>
        );
      case "prompt":
        return (
          <div className="flex flex-col">
            <p className="text-sm line-clamp-2">{cellValue}</p>
          </div>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <span onClick={() => handleOpenDialog(variable)} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete">
              <span onClick={() => handleDeleteVariable(variable)} className="text-lg text-danger cursor-pointer active:opacity-50">
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return cellValue;
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    viewModel.setCurrentVariable({ ...viewModel.currentVariable, [name]: value })
  };

  const handleSaveVariable = () => {
    viewModel.saveVariable().then(() => {
      const message = currentVariable.id ? 'Variable updated' : 'Variable created';
      toast.success(message);
    });
  };

  const topContent = React.useMemo(() => {
    return (
      <Button
        className='w-fit'
        color="primary"
        onClick={() => handleOpenDialog()}
      >
        Add Variable
      </Button>
    )
  }, [handleOpenDialog])

  if (viewModel.isLoading) {
    <div className='w-full flex items-center justify-center'>
      <Spinner />
    </div>
  }

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  const { currentVariable } = viewModel;

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <Toaster />
        <Modal
          isOpen={isDeleteModalOpen}
          onOpenChange={(open) => {
            setIsDeleteModalOpen(open);
            if (!open) setVariableToDelete(null);
          }}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Confirm Deletion</ModalHeader>
                <ModalBody>
                  <p>{`Are you sure you want to delete variable ${variableToDelete?.name}?`}</p>
                  <p className="text-sm text-default-400">This action cannot be undone.</p>
                </ModalBody>
                <ModalFooter>
                  <Button color="default" variant="flat" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button
                    color="danger"
                    onPress={() => {
                      if (variableToDelete) {
                        viewModel.deleteVariable(variableToDelete.id)
                          .then(() => {
                            toast.success('Variable deleted');
                            onClose();
                          });
                      }
                    }}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Modal
          isOpen={viewModel.openDialog}
          onOpenChange={() => viewModel.setOpenDialog(false)}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">{currentVariable.id ? 'Edit Variable' : 'Add Variable'}</ModalHeader>
                <ModalBody>
                  <Input
                    name="name"
                    label="Variable Name"
                    type="text"
                    value={currentVariable.name}
                    onChange={handleInputChange}
                  />
                  <Textarea
                    name="description"
                    label="Description"
                    type="text"
                    value={viewModel.currentVariable.description}
                    onChange={handleInputChange}
                  />
                  <Textarea
                    name="prompt"
                    label="Prompt"
                    type="text"
                    fullWidth
                    value={viewModel.currentVariable.prompt}
                    onChange={handleInputChange}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onPress={onClose}>
                    Close
                  </Button>
                  <Button color="primary" onPress={handleSaveVariable}>
                    Save
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Table
          aria-label="Example table with dynamic content"
          isHeaderSticky
          layout='fixed'
          className='h-full'
          topContent={topContent}
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody items={viewModel.variables}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
});

export default VariablesPage;