import { useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticationViewModel from '../viewModels/AuthenticationViewModel';

const OAuthCallbackPage = () => {
  const viewModel = useMemo(() => new AuthenticationViewModel(), []);
  const isHandlingAuth = useRef(false);
  const navigate = useNavigate();

  const handleCallback = useCallback(async (code: string) => {
    const token = await viewModel.exchangeCodeForToken(code);
    if (token) {
      navigate('/', { replace: true })
    }
  }, [viewModel, navigate]);

  useEffect(() => {
    if (isHandlingAuth.current) return;
    isHandlingAuth.current = true;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleCallback(code);
    }
  }, [viewModel, navigate, handleCallback]);

  const { isLoading, error } = viewModel;

  return (
    <div>
      <h1>Exchanging code for token...</h1>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default OAuthCallbackPage;