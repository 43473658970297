import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DashboardPageViewModel from '../viewModels/DashboardPageViewModel';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Spinner } from '@nextui-org/react';

interface DashboardItemProps {
  title: string;
  count: number;
  link: string;
}

function DashboardItem({ title, count, link }: DashboardItemProps) {
  return (
    <Card as={Link} to={link} className='p-3' isHoverable>
      <CardHeader className="pb-0 pt-2 px-4 flex-col text-center">
        <p className="text-tiny uppercase font-bold">
          {title}
        </p>
        <h1 className="font-bold text-4xl">
          {count}
        </h1>
      </CardHeader>
    </Card>
  );
}

const DashboardPage = observer(() => {
  const [viewModel] = useState(() => new DashboardPageViewModel());

  useEffect(() => {
    viewModel.fetchCounts();
  }, [viewModel]);

  if (viewModel.isLoading) {
    <div className='w-full flex items-center justify-center'>
      <Spinner />
    </div>
  }

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <div className="flex flex-row gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <DashboardItem title="Criteria" count={viewModel.counts.criteria} link="/criteria" />
          <DashboardItem title="Variables" count={viewModel.counts.variables} link="/variables" />
          <DashboardItem title="Attributes" count={viewModel.counts.attributes} link="/attributes" />
          <DashboardItem title="Recipients" count={viewModel.counts.recipients} link="/recipients" />
          <DashboardItem title="Segmentations" count={viewModel.counts.segmentations} link="/segmentation" />
        </div>
      </div>
    </div>
  );
});

export default DashboardPage;