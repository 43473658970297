import { runInAction, makeObservable, observable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import BaseViewModel from './BaseViewModel';

export interface Recipient {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  is_synthetic: boolean;
}

interface Data {
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  recipients: Recipient[];
}

export default class RecipientsPageViewModel extends BaseViewModel {
  data: Data = { currentPage: 0, itemsPerPage: 0, totalPages: 1, totalItems: 0, recipients: [] };
  isLoading = false;
  error: string | null = null;
  itemsPerPage = 10

  constructor() {
    super();
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      itemsPerPage: observable,
      error: observable,
      fetchData: action,
      setItemsPerPage: action
    });

    if (!isHydrated(this) && !isPersisting(this)) {
      makePersistable(this, {
        name: 'RecipientsPageViewModelStorage',
        properties: ['itemsPerPage'],
        storage: window.localStorage
      });
    }
  }

  async fetchData(search: string, page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const response = await this.api.get<Data>('/recipients', {
        params: {
          search,
          page,
          per_page: this.itemsPerPage
        }
      });
      runInAction(() => {
        this.data = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch attributes. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  setItemsPerPage(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
  }
}
