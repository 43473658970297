import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';

interface Counts {
  criteria: number;
  variables: number;
  attributes: number;
  recipients: number;
  segmentations: number;
}

export default class DashboardPageViewModel extends BaseViewModel {
  counts: Counts = {
    criteria: 0,
    variables: 0,
    attributes: 0,
    recipients: 0,
    segmentations: 0,
  };

  isLoading = false;
  error: string | null = null;

  constructor() {
    super();
    makeObservable(this, {
      counts: observable,
      isLoading: observable,
      error: observable,
      fetchCounts: action,
    });
  }

  async fetchCounts() {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });

    try {
      const [criteria, variables, attributes, recipients, segmentations] = await Promise.all([
        this.api.get<{ count: number }>('/criteria/count'),
        this.api.get<{ count: number }>('/variables/count'),
        this.api.get<{ count: number }>('/attributes/count'),
        this.api.get<{ count: number }>('/recipients/count'),
        this.api.get<{ count: number }>('/segmentations/count'),
      ]);

      runInAction(() => {
        this.counts = {
          criteria: criteria.data.count,
          variables: variables.data.count,
          attributes: attributes.data.count,
          recipients: recipients.data.count,
          segmentations: segmentations.data.count,
        };
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Error fetching dashboard data';
        this.isLoading = false;
      });
    }
  }
}
