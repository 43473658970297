import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';

export interface UserTenant {
  id: string;
  is_active: boolean;
  tenant: {
    name: string;
  };
}

export default class PreferencesPageViewModel extends BaseViewModel {
  userTenants: UserTenant[] = [];

  constructor() {
    super();
    makeObservable(this, {
      userTenants: observable,
      isLoading: observable,
      error: observable,
      activateUserTenant: action
    });
    this.fetchData();
  }

  async fetchData() {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const response = await this.api.get<UserTenant[]>('/user_tenants/all');      
      runInAction(() => {
        this.userTenants = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch user tenants. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async activateUserTenant(userTenantId: string) {
    await this.api.post(`/user_tenants/activate/${userTenantId}`);
    this.fetchData()
  }
}