import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';

import AppLayout from './components/AppLayout';
import { routes } from './routes';
import OAuthCallbackPage from './pages/OAuthCallbackPage';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';

function AppContent() {
  const { isDark } = useTheme();

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: <ProtectedRoute><DashboardPage /></ProtectedRoute>,
        },
        {
          path: '/oauth/callback',
          element: <OAuthCallbackPage />,
        },
        ...routes.map(route => ({
          path: route.path,
          element: <ProtectedRoute>{route.element}</ProtectedRoute>,
        })),
        {
          path: '/login',
          element: <LoginPage />,
        },
      ],
    },
  ]);

  return (
    <NextUIProvider>
      <main className={`${isDark ? 'dark' : 'light'} text-foreground bg-background`}>
        <RouterProvider router={router} />
      </main>
    </NextUIProvider>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;