import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';
import Segmentation from '../models/Segmentation';
import AuthStore from '../stores/AuthStore';
import { Status } from '../models/Segmentation';

interface Data {
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  segmentations: Segmentation[];
}

export default class SegmentationsViewModel extends BaseViewModel {
  data: Data = { currentPage: 1, itemsPerPage: 10, totalPages: 1, totalItems: 0, segmentations: [] };
  isLoading = false;
  error: string | null = null;
  itemsPerPage = 10
  currentSegmentation: Segmentation | null = null;
  private websocket: WebSocket | null = null;

  constructor() {
    super();
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      itemsPerPage: observable,
      currentSegmentation: observable,
      error: observable,
      fetchData: action,
      setItemsPerPage: action,
      addSegmentation: action,
      deleteSegmentation: action,
      editSegmentation: action,
      setCurrentSegmentation: action,
    });
    this.initializeWebSocket();
  }

  async fetchData(search: string, page: number) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const response = await this.api.get<Data>('/segmentations', {
        params: {
          search,
          page,
          per_page: this.itemsPerPage
        }
      });
      runInAction(() => {
        this.data = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch segmentations. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  setItemsPerPage(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
  }

  setCurrentSegmentation(segmentation: Segmentation | null) {
    this.currentSegmentation = segmentation;
  }

  async addSegmentation(name: string) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const response = await this.api.post<Segmentation>('/segmentations', { name });
      runInAction(() => {
        this.data.segmentations = [...this.data.segmentations, response.data];
        this.isLoading = false;
      });
      return response.data;
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to add segmentation. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async deleteSegmentation(id: number) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      await this.api.delete(`/segmentations/${id}`);
      runInAction(() => {
        this.data.segmentations = this.data.segmentations.filter(segmentation => segmentation.id !== id);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to delete segmentation. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async editSegmentation(segmentation: Segmentation) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const response = await this.api.put<Segmentation>(`/segmentations/${segmentation.id}`, { name: segmentation.name });
      runInAction(() => {
        const updatedSegmentations = this.data.segmentations.map(s => s.id === segmentation.id ? response.data : s);
        this.data.segmentations = updatedSegmentations;
        this.isLoading = false;
      });
      return response.data;
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to edit segmentation. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  private initializeWebSocket = () => {
    if (this.websocket) {
      return;
    }
    const url = process.env.REACT_APP_WSS_URL || 'ws://localhost:4000';
    this.websocket = new WebSocket(`${url}/ws/segmentations?authorization=${AuthStore.token}`);

    this.websocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    this.websocket.onmessage = (event) => {
      interface StatusUpdateMessage {
        segmentation_id: number;
        status: Status;
      }

      const data = JSON.parse(event.data) as StatusUpdateMessage;

      runInAction(() => {
        // Update status in the segmentations list
        const segmentation = this.data.segmentations.find(s => s.id === data.segmentation_id);
        if (segmentation) {
          segmentation.status = data.status;
        }

        // Also update currentSegmentation if it matches
        if (this.currentSegmentation?.id === data.segmentation_id) {
          this.currentSegmentation.status = data.status;
        }
      });
    };

    this.websocket.onerror = (error) => {
      runInAction(() => {
        this.error = `WebSocket error: ${error}`;
      });
    };

    this.websocket.onclose = () => {
      this.websocket = null;
    };
  }
}