import { runInAction, makeObservable, observable, action } from 'mobx';

import BaseViewModel from './BaseViewModel';
import AuthStore from '../stores/AuthStore';
import Segmentation from '../models/Segmentation';
import { Status } from '../models/Segmentation';

export default class SegmentationProcessingViewModel extends BaseViewModel {
  currentSegmentation?: Segmentation = undefined;
  task_id?: string = undefined;
  // segmentationMessages: SegmentationMessage[] = [];

  isLoading = false;
  error: string | null = null;

  private websocket: WebSocket | null = null;

  constructor() {
    super();
    makeObservable(this, {
      currentSegmentation: observable,
      // segmentationStatuses: observable,
      // segmentationMessages: observable,
      setCurrentSegmentation: action,
      startPreview: action,
      startProcessing: action,
      stopProcessing: action,
      // progress: computed,
      // status: computed,
      // messages: computed
    });
    this.initializeWebSocket();
  }

  // get messages() {
  //   return this.segmentationMessages.filter((message) => message.message.length);
  // }

  // get progress() {
  //   const lastProgressMessage = this.segmentationMessages.findLast((message) => message.type === MessageType.INFO);
  //   return lastProgressMessage ? lastProgressMessage.progress : 0;
  // }

  // get status() {
  //   if (!this.segmentationStatuses || this.segmentationStatuses.length === 0) {
  //     return Status.NO_STATUS;
  //   }
  //   return this.segmentationStatuses[this.segmentationStatuses.length - 1]?.status ?? Status.NO_STATUS;
  // }

  setCurrentSegmentation = (segmentation: Segmentation) => {
    this.currentSegmentation = segmentation;
    // this.segmentationMessages = [];
  }

  startPreview = () => {
    // this.segmentationMessages = [];
    // this.createCommand(CommandAction.PREVIEW);
  }

  startProcessing = async () => {
    if (!this.currentSegmentation) {
      return;
    }
    try {
      const response = await this.api.post<{ task_id: string }>(`/segmentations/${this.currentSegmentation.id}/run`);
      this.task_id = response.data.task_id;
    } catch (err) {
      this.error = `Failed to start ${err}`;
    }
  }

  stopProcessing = () => {
    // this.createCommand(CommandAction.STOP);
  }

  // private createCommand = async (action: CommandAction) => {
  //   if (!this.currentSegmentation) {
  //     return;
  //   }

  //   try {
  //     await this.api.post('/commands', {
  //       segmentation_id: this.currentSegmentation.id,
  //       action: action
  //     });
  //   } catch (err) {
  //     this.error = `Failed to start ${action} ${err}`;
  //   }
  // }    

  // private updateStatus = async () => {
  //   if (!this.currentSegmentation) {
  //     return;
  //   }

  //   try {
  //     const response = await this.api.get<{ status: Status }>(`/segmentations/${this.currentSegmentation.id}/status`);
  //     runInAction(() => {
  //       if (this.currentSegmentation) {
  //         this.currentSegmentation.status = response.data.status;
  //       }
  //     });
  //   } catch (error) {
  //     runInAction(() => {
  //       this.error = `Error while updating status ${error}`
  //     });
  //   }
  // }

  // private updateMessages = async () => {
  //   if (!this.currentSegmentation) {
  //     return;
  //   }

  //   try {
  //     const response = await this.api.get<SegmentationMessage[]>(
  //       `/segmentationMessages/${this.currentSegmentation.id}`,
  //       {
  //         params: {
  //           after_id: this.segmentationMessages.length > 0 ? this.segmentationMessages[this.segmentationMessages.length - 1]?.id : undefined
  //         }
  //       }
  //     );
  //     runInAction(() => {
  //       this.segmentationMessages.push(...response.data);
  //     });
  //   } catch (error) {
  //     runInAction(() => {
  //       this.error = `Error while updating messages ${error}`
  //     });
  //   }
  // }

  private initializeWebSocket = () => {
    if (this.websocket) {
      return;
    }
    const url = process.env.REACT_APP_WSS_URL || 'ws://localhost:4000';
    this.websocket = new WebSocket(`${url}/ws/segmentations?authorization=${AuthStore.token}`);

    this.websocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    this.websocket.onmessage = (event) => {
      interface StatusUpdateMessage {
        segmentation_id: number;
        status: Status;
      }
      const data = JSON.parse(event.data) as StatusUpdateMessage;
      runInAction(() => {
        if (this.currentSegmentation && this.currentSegmentation.id === data.segmentation_id) {
          this.currentSegmentation.status = data.status;
        }
      });
    };

    this.websocket.onerror = (error) => {
      runInAction(() => {
        this.error = `WebSocket error: ${error}`;
      });
    };

    this.websocket.onclose = () => {
      this.websocket = null;
    };
  }
}