import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import Chart from "react-apexcharts";

import SegmentationConfigurationViewModel from '../viewModels/SegmentationConfigurationViewModel';
import SegmentationProcessingViewModel from '../viewModels/SegmentationProcessingViewModel';
import SegmentsComponent from '../components/SegmentsComponent';
import SegmentationCriteriaList from '../components/SegmentationCriteriaList';
import SegmentPersonalizationComponent from '../components/SegmentPersonalizationComponent';
import SegmentationCriterion from '../models/SegmentationCriterion';
import SegmentationResultViewModel from '../viewModels/SegmentationResultViewModel';
import { Segment } from '../models/Segment';
import { Status } from '../models/Segmentation';

const SegmentationPage = observer(() => {
  const { id, segmentId } = useParams();

  const navigate = useNavigate();
  const [viewModel] = useState(() => new SegmentationConfigurationViewModel());
  const [processingViewModel] = useState(() => new SegmentationProcessingViewModel());
  const [resultViewModel] = useState(() => new SegmentationResultViewModel(Number(id)));

  useEffect(() => {
    if (id && !isNaN(Number(id))) {
      viewModel.fetchSegmentation(Number(id));
      resultViewModel.setSegmentationId(Number(id));
    }
  }, [resultViewModel, viewModel, id]);

  useEffect(() => {
    if (viewModel.currentSegmentation && processingViewModel) {
      processingViewModel.setCurrentSegmentation(viewModel.currentSegmentation);
    }
  }, [viewModel.currentSegmentation, processingViewModel]);

  useEffect(() => {
    if (segmentId) {
      resultViewModel.setSelectedSegmentId(segmentId);
    }
  }, [resultViewModel, segmentId]);

  useEffect(() => {
    if (processingViewModel.currentSegmentation?.status === Status.PROCESS_DONE) {
      resultViewModel.fetchData();
    }
  }, [processingViewModel.currentSegmentation?.status, resultViewModel]);

  const handleCriteriaDelete = (segmentationCriterion: SegmentationCriterion) => {
    if (segmentationCriterion) {
      viewModel.deleteCriterion(segmentationCriterion);
    }
  };

  const handleCriteriaAdd = (id: string) => {
    if (viewModel.currentSegmentation?.id) {
      const newPriority = viewModel.currentSegmentation.segmentationCriteria?.length ?? 0;
      viewModel.addCriterion(id, newPriority, viewModel.currentSegmentation.id);
    }
  };

  const handleCriterionCreate = (name: string, prompt: string) => {
    if (viewModel.currentSegmentation?.id) {
      const newPriority = viewModel.currentSegmentation.segmentationCriteria?.length ?? 0;
      viewModel.createCriterion(name, prompt, newPriority, viewModel.currentSegmentation.id);
    }
  };

  const handlePreviewStart = () => {
    //  resultViewModel.clearData();
    //  processingViewModel.startPreview();
  };

  const handleProcessStart = () => {
    resultViewModel.clearData();
    processingViewModel.startProcessing();
  };

  const handleStop = () => {
    //  processingViewModel.stopProcessing();
  };

  const { availableCriteria, currentSegmentation } = viewModel;
  const { currentPCView, variablesWithPersonalizations, segments } = resultViewModel;

  const chartSeries = useMemo(() => 
    resultViewModel.currentVisualizationData.map(cluster => ({
      name: cluster.name,
      data: cluster.data.map(point => [point.x, point.y]),
    }))
  , [resultViewModel.currentVisualizationData]);

  const chartOptions = useMemo(() => ({
    chart: {
      id: 'apexchart-example',
      toolbar: {
        show: false
      },
      colors: resultViewModel.currentVisualizationData.map(cluster => cluster.fill),
    },
    tooltip: {
      enabled: false
    },
    zoom: {
      type: ''
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true
        },        
      },
      yaxis: {
        lines: {
          show: true
        },        
      }
    },
    markers: {
      shape: ['circle'],
      size: 4,
    },
    xaxis: {
      min: -1.2,
      max: 1.2,
      type: 'numeric',
      labels: {
        show: false
      },
    },
    yaxis: {
      min: -1.2,
      max: 1.2,
      type: 'numeric',
      labels: {
        show: false
      },
    },
  }), [resultViewModel.currentVisualizationData]);

  return (
    <div className='flex-1 p-4 overflow-y-auto'>
      <h1 className='text-2xl font-bold p-2'>
        Segmentation: {currentSegmentation?.name}
      </h1>
      <div className='grid grid-cols-3 gap-2'>
        <div className='col-span-1 p-2 max-h-[500px] flex flex-col gap-2'>
          <h1 className='text-2xl font-bold'>Criteria</h1>
          {currentSegmentation && (
            <div className='flex-1 min-h-0'>
              <SegmentationCriteriaList
                availableCriteria={availableCriteria}
                segmentation={currentSegmentation}
                status={currentSegmentation.status}
                onCriteriaAdd={handleCriteriaAdd}
                onCriterionCreate={handleCriterionCreate}
                onCriteriaDelete={handleCriteriaDelete}
                onPreviewStart={handlePreviewStart}
                onProcessStart={handleProcessStart}
                onStop={handleStop}
              />
            </div>
          )}
        </div>
        <div className='col-span-1 p-2 max-h-[500px] flex flex-col gap-2'>
          <h1 className='text-2xl font-bold'>Segments</h1>
          {currentSegmentation && (
            <div className='flex-1 min-h-0'>
              <SegmentsComponent
                onSelectedSegmentChange={(segment: Segment) => {
                  navigate(`/segmentations/${id}/${segment.id}`);
                }}
                segments={segments}
                selectedSegmentId={segmentId}
                status={currentSegmentation.status}
                isLoading={resultViewModel.isLoading}
                error={resultViewModel.error}
              />
            </div>
          )}
        </div>
        <div className='col-span-1 p-2 max-h-[500px] flex flex-col gap-2'>
          {/* <div className="flex justify-between items-center mb-4">
            <h1 className='text-2xl font-bold'>Cluster Visualization</h1>
            <select
              value={resultViewModel.currentPCView}
              onChange={(e) => resultViewModel.setCurrentPCView(e.target.value as any)}
              className="border rounded p-1"
            >
              <option value="pc1_pc2">PC1 vs PC2</option>
              <option value="pc2_pc3">PC2 vs PC3</option>
              <option value="pc3_pc1">PC3 vs PC1</option>
            </select>
          </div> */}
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="scatter"
            width={320}
            height={320}
          />
        </div>
        <div className='col-span-3 h-full p-2 flex flex-col'>
          {segments && (
            <SegmentPersonalizationComponent
              selectedSegmentId={segmentId}
              variablesWithPersonalizations={variablesWithPersonalizations}
              isLoading={resultViewModel.isLoading}
              error={resultViewModel.error}
              status={currentSegmentation?.status}
            />
          )}
        </div>
      </div>
    </div >
  );
});

export default SegmentationPage;