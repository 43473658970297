import AttributesPage from './pages/AttributesPage';
import CriteriaPage from './pages/CriteriaPage';
import DashboardPage from './pages/DashboardPage';
import PreferencesPage from './pages/PreferencesPage';
import RecipientsPage from './pages/RecipientsPage';
import SegmentationPage from './pages/SegmentationPage';
import SegmentationsPage from './pages/SegmentationsPage';
import VariablesPage from './pages/VariablesPage';

interface Route {
  path: string;
  element: React.ReactNode;
  label: string;
  includeInNav?: boolean;
}

export const routes: Route[] = [
  { path: '/dashboard', label: 'Dashboard', element: <DashboardPage />, includeInNav: true },
  { path: '/criteria', label: 'Criteria', element: <CriteriaPage />, includeInNav: true },
  { path: '/variables', label: 'Variables', element: <VariablesPage />, includeInNav: true },
  { path: '/attributes', label: 'Attributes', element: <AttributesPage />, includeInNav: true },
  { path: '/recipients', label: 'Recipients', element: <RecipientsPage />, includeInNav: true },
  { path: '/segmentations', label: 'Segmentations', element: <SegmentationsPage />, includeInNav: true },
  { path: '/segmentations/:id', label: 'Segmentation', element: <SegmentationPage />, includeInNav: false },
  { path: '/segmentations/:id/:segmentId', label: 'Segmentation', element: <SegmentationPage />, includeInNav: false },
  { path: '/preferences', label: 'Preferences', element: <PreferencesPage />, includeInNav: true },
];