import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AuthStore from '../stores/AuthStore';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
  const authStore = AuthStore;

  if (!authStore.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
});

export default ProtectedRoute;