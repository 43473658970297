import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';
import Segmentation from '../models/Segmentation';
import SegmentationCriterion from '../models/SegmentationCriterion';
import Criterion from '../models/Criterion';

export default class SegmentationConfigurationViewModel extends BaseViewModel {
  availableCriteria: Criterion[] = [];
  currentSegmentation?: Segmentation = undefined;
  isLoading = false;
  error: string | null = null;

  constructor() {
    super();
    makeObservable(this, {
      availableCriteria: observable,
      currentSegmentation: observable,
      isLoading: observable,
      error: observable,
      fetchData: action,
      fetchSegmentation: action,
      addCriterion: action,
      createCriterion: action,
      deleteCriterion: action
    });
    this.fetchData();
  }

  async fetchData() {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const availableCriteriaResponse = await this.api.get<Criterion[]>('/criteria');
      runInAction(() => {
        this.availableCriteria = availableCriteriaResponse.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch attributes. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async fetchSegmentation(id: number) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const response = await this.api.get<Segmentation>(`/segmentations/${id}`);
      runInAction(() => {
        this.currentSegmentation = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch segmentation. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async addCriterion(id: string, priority: number, segmentationId: number) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const response = await this.api.post<SegmentationCriterion>(`/segmentation_criterion`, { criterion_id: Number(id), priority, segmentation_id: segmentationId });
      runInAction(() => {
        if (this.currentSegmentation) {
          this.currentSegmentation = {
            ...this.currentSegmentation,
            segmentationCriteria: [...(this.currentSegmentation.segmentationCriteria || []), response.data]
          };
        }
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to add criterion. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async deleteCriterion(segmentationCriterion: SegmentationCriterion) {
    if (!this.currentSegmentation) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      await this.api.delete(`/segmentation_criterion/${segmentationCriterion.id}`);
      runInAction(() => {
        if (this.currentSegmentation) {
          this.currentSegmentation = {
            ...this.currentSegmentation,
            segmentationCriteria: this.currentSegmentation.segmentationCriteria?.filter(
              c => c.criterion.id !== segmentationCriterion.criterion.id
            ) || []
          };
        }
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to delete criterion. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async createCriterion(name: string, prompt: string, priority: number, segmentationId: number) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const createResponse = await this.api.post<Criterion>('/criteria', { name, prompt });
      const addResponse = await this.api.post<SegmentationCriterion>(`/segmentation_criterion`, { criterion_id: createResponse.data.id, priority, segmentation_id: segmentationId });
      runInAction(() => {
        if (this.currentSegmentation) {
          this.currentSegmentation = {
            ...this.currentSegmentation,
            segmentationCriteria: [...(this.currentSegmentation.segmentationCriteria || []), addResponse.data]
          };
        }
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to create criterion. Please try again later.';
        this.isLoading = false;
      });
    }
  }
}