import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class AuthStore {
  token: string | null = null;

  constructor() {
    makeObservable(this, {
      token: observable,
      clearToken: action,
      setToken: action,
      isAuthenticated: computed,
    });
    makePersistable(this, {
      name: 'AuthStoreStorage',
      properties: ['token'],
      storage: window.localStorage
    });
  }

  clearToken() {
    this.token = null;
  }

  setToken(token: string) {
    this.token = token;
  }

  get isAuthenticated() {
    return !!this.token;
  }
}

export default new AuthStore();